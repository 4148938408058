.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: 130px;
}

.App-title {
  font-size: 1.5em;
}

.App-title h1 {
  cursor: pointer;
  line-height: 75px !important;
}

.visible-on-screen {
  display: none;
}

.app-bar-icon-right {
  top: -0.15em;
}

.App-container {
  width: 95%;
  margin: 0 auto;
}

.App-intro-content {
  font-size: 1em;
  width: 80%;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 1em;
}

.App-intro-content ul {
  padding-left: 1em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.App-intro-content ul li {
  padding: 5px 0px;
}

table {
  border: 1px solid #000;
}

.institute-logo {
  width: 100%;
  padding-bottom: 1em;
}

.institute-logo-link:first-child {
  width: 80%;
}

.institute-logo-link {
  width: 80%;
  display: inline-block;
}

.leaderboard-container {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  overflow-x: auto;
  font-size: 0.9em;
}

.leaderboard-container td {
  width: 3em;
  padding-left: 0em;
  padding-right: 0em;
  padding-top: 0em;
  padding-bottom: 0em;
  white-space: pre;
  text-overflow: ellipsis;
}

.leaderboard-container th {
  width: 3em;
  padding-left: 0em;
  padding-right: 0em;
  padding-top: 0em;
  padding-bottom: 0em;  white-space: pre;
  text-overflow: ellipsis;
}

.leaderboard-container td {
  display: none;
}

.leaderboard-container th {
  display: none;
}

.leaderboard-container th:nth-child(1) {
  display: none;
  width: 2em !important;
}

.leaderboard-container th:nth-child(2) {
  display: table-cell;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.leaderboard-container th:nth-child(3) {
  display: table-cell;
}

.leaderboard-container th:nth-child(4) {
  display: table-cell;
}

.leaderboard-container th:nth-child(6) {
  display: table-cell;
  padding-right: 0.5em;
}

.leaderboard-container td:nth-child(1) {
  display: none;
  width: 2em !important;
  padding-left: 0.2em;
}

.leaderboard-container td:nth-child(2) {
  display: table-cell;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.leaderboard-container td:nth-child(3) {
  display: table-cell;
  padding-left: 0.2em;
}

.leaderboard-container td:nth-child(4) {
  display: table-cell;
}

.leaderboard-container td:nth-child(6) {
  display: table-cell;
  padding-right: 0.5em;
}

.leaderboard-container td:nth-child(2) {
  width: 3em;
}

.leaderboard-container td:nth-child(3) {
  min-width: 13em;
}

.leaderboard-container td:nth-child(4) {
  width: 15em;
}

.leaderboard-container td:nth-child(9) {
  width: 4em;
}

.leaderboard-container td:nth-child(10) {
  width: 4em;
}

.leaderboard-container td:nth-child(11) {
  width: 4em;
}

.leaderboard-container td:last-child {
  padding-right: 1em !important;
}

.leaderboard-container th:nth-child(2) {
  width: 3em;
}

.leaderboard-container th:nth-child(3) {
  min-width: 13em;
}

.leaderboard-container th:nth-child(4) {
  width: 15em;
}

.leaderboard-container th:nth-child(9) {
  width: 4em;
}

.leaderboard-container th:nth-child(10) {
  width: 4em;
}

.leaderboard-container th:nth-child(11) {
  width: 4em;
}

.leaderboard-container table {
  width: 100%;
}
.leaderboard-container th:last-child {
  padding-right: 1em !important;
}


td,th {
  font-size: 0.9em !important;
}

.diagnostics-table {
  border: none;
}

.diagnostics-table td {
  width: 2em !important;
  display: inline-block;
  text-align: left;
}

.diagnostics-table th {
  width: 2em !important;
  display: inline-block;
  text-align: left;
}

.other-container {
  font-size: 1.1em;
  text-align: center;
  width: 97%;
  margin: 0 auto;
}

.other-container th {
  text-align: left !important;
}

.other-container td {
  text-align: left !important;
}

.other-container th:nth-child(1) {
  width: 14em;
}

.other-container th:nth-child(3) {
  display: none;
}

.other-container th:last-child {
  display: none;
}

.other-container td:nth-child(1) {
  width: 10em;
}

.other-container td:nth-child(3) {
  display: none;
}

.other-container td:last-child {
  display: none;
}

.tasks-container th:nth-child(1) {
  display: none;
}

.tasks-container th:nth-child(4) {
  text-align: center !important;
}

.tasks-container td:nth-child(1) {
  display: none;
}

.tasks-container td:nth-child(4) {
  text-align: center !important;
}

.profile-container th:nth-child(4) {
  display: table-cell;
  text-align: left !important;
}

.profile-container th:first-child {
  width: 10em;
}

.profile-container td:first-child {
  width: 10em;
}

.profile-container th:nth-child(2) {
  display: none;
}

.profile-container td:nth-child(2) {
  display: none;
}

.profile-container th:nth-child(3) {
  display: none;
}

.profile-container td:nth-child(3) {
  display: table-cell;
  text-align: right !important;
}

.profile-container td:nth-child(3) {
  display: none;
}

.centered-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
/* Task Column */
.submission-container th:nth-child(1) {
  display: none
}
/* Small task column */
.submission-container th:nth-child(2) {
  display: table-cell;
}
/* Small task column */
.submission-container th:nth-child(3) {
  display: none;
}
/* Per task score Column */
.submission-container th:nth-child(4) {
  display: table-cell;
  text-align: center !important;
}
/* Task Column */
.submission-container td:nth-child(1) {
  display: none
}
/* Small task name column */
.submission-container td:nth-child(2) {
  display: table-cell;
}
/* Task metric column */
.submission-container td:nth-child(3) {
  display: none;
}

/* Per task score Column */
.submission-container td:nth-child(4) {
  display: table-cell;
  text-align: right !important;
}


.detail-container {
  text-align: left;
}

a {
  color: #2b5283;
  text-decoration: none;
  border-bottom: 1px dotted #2b5283;
}

a:hover {
  border-bottom: 1px dotted #2b5283;
}

.active-link {
  color: #e65f57 !important;
}

.active-link span {
  color: #e65f57 !important;
}

strong {
  color: #e65f57;
}

/* Styles added by Julian */

body {
  /* looks better and is easier to read */
  line-height: 1.4;
}

.diagnostic-page a {
  /* ideal color for light theme */
  /* color: rgb(65, 100, 150); */
  /* ideal color for dark theme / compromise color for light theme */
  /* (consider using for links across whole site?) */
  color: #2b5283;
  border-bottom: 1px dotted #2b5283;
}

.diagnostic-page code {
    margin: 0;
    padding: 0.1em 0.2em;
    background-color: rgba(27, 31, 35 ,0.05);
    border-radius: 3px;
}

.diagnostic-page h2 {
    border-bottom: 1px solid rgb(127, 127, 127);
}
.diagnostic-page h3 {
    border-bottom: 1px solid rgb(160, 160, 160);
}

/* end Julian-added styles */

.App-title a:hover {
  border-bottom: 1px solid transparent;
}

.App-title a:hover span {
  color: #e65f57 !important;
}

.App-title div > div > span:hover {
  color: #e65f57 !important;
}

.App-title-main span:hover {
  cursor: pointer;
}

.App-title-main span a {
  color: #ffffff !important;
}
.App-title-main span:hover a {
  color: #2b5283 !important;
}

.App-title-main a:hover {
  border-bottom: 0px solid #000;
}

.icon-red {
  display: none
}

table {
  border: 1px solid #494647;
  border-radius: 2px;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media only screen and (min-width: 768px) {
  .App-header {
    height: 150px;
  }

  td,th {
    font-size: 1em !important;
  }

  .institute-logo-link:first-child {
    width: 20%;
  }
  .institute-logo-link {
    width: 30%;
    padding: 1em;
    display: inline-block;
  }

  .institute-logos {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .leaderboard-container {
    width: 90%;
    font-size: 0.9em;
  }

  .leaderboard-container th {
    text-align: right !important;
  }

  .leaderboard-container th:nth-child(1) {
    text-align: left !important;
    display: table-cell;
  }

  .leaderboard-container th:nth-child(2) {
    text-align: left !important;
  }

  .leaderboard-container th:nth-child(3) {
    text-align: left !important;
  }

  .leaderboard-container th:nth-child(4) {
    text-align: left !important;
  }

  .leaderboard-container td:nth-child(1) {
      display: table-cell;
  }

  .tasks-container {
    width: 90%;
    margin: 0 auto;
  }

  .tasks-container th:first-child {
    display: table-cell;
    width: 20em !important;
  }

  .tasks-container th:nth-child(2) {
    display: none;
  }

  .tasks-container th:nth-child(3) {
    text-align: center !important;
    display: table-cell;
  }

  .tasks-container th:nth-child(4) {
    text-align: center !important;
  }

  .tasks-container th:last-child {
    display: table-cell;
    width: 11em;
  }

  .tasks-container td:first-child {
    display: table-cell;
    width: 20em !important;
  }

  .tasks-container td:nth-child(2) {
    display: none;
  }

  .tasks-container td:nth-child(3) {
    display: table-cell;
    text-align: center !important;
  }

  .tasks-container td:last-child {
    display: table-cell;
    width: 11em;
  }

  .profile-container th:first-child {
    width: 20em;
  }

  .profile-container td:first-child {
    width: 20em;
  }

  .profile-container th:last-child {
    display: table-cell;
    text-align: left !important;
  }

  .profile-container th:nth-child(2) {
    display: table-cell;
    text-align: center !important;
    width: 3em;
  }

  .profile-container td:nth-child(2) {
    display: table-cell;
    text-align: center !important;
    width: 3em;
  }

  .profile-container th:nth-child(3) {
    display: table-cell;
    text-align: center !important;
  }

  .profile-container td:nth-child(3) {
    display: table-cell;
    text-align: center !important;
  }

  .profile-container th:last-child {
    display: table-cell;
    text-align: left !important;
    width: 4em;
  }

  .profile-container td:last-child {
    display: table-cell;
    text-align: left !important;
    width: 4em;
  }

  .profile-container {
    width: 90%;
    margin: 0 auto;
  }

  .submission-container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .submission-container th:nth-child(3) {
    display: table-cell;
  }

  .submission-container td:nth-child(3) {
    display: table-cell;
    text-align: left !important;
  }

  .submission-container th:nth-child(1) {
    display: table-cell;
    width: 20em !important;
  }

  .submission-container td:nth-child(1) {
    display: table-cell;
    width: 20em !important;
  }

  .submission-container th:nth-child(2) {
    display: none;
  }

  .submission-container td:nth-child(2) {
    display: none;
  }

  .submission-container th:last-child {
    text-align: right !important;
  }

  .App-container {
    width: 70%;
    font-size: 0.9em;
  }

  .App-intro-content {
    width: 90%;
    text-align: justify;
  }


 .other-container {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1024px)  {
  .leaderboard-container {
    width: 95%;
    font-size: 0.8em !important;
  }

  .leaderboard-container td {
    display: table-cell;
  }

  .leaderboard-container th {
    display: table-cell;
  }

  .task-row-column {
    width: 3em;
  }

  .task-score-column {
    width: 4em !important;
    text-align: right !important;
  }

  .leaderboard-container th:nth-child(5) {
    text-align: left !important;
  }

  .leaderboard-container td:nth-child(5) {
    text-align: center !important;
  }

  .leaderboard-container th:nth-child(11) {
    min-width: 5em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(11) {
    min-width: 5em !important;
  }
  .leaderboard-container th:nth-child(9) {
    min-width: 5em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(9) {
    min-width: 4em !important;
  }
  .leaderboard-container td:nth-child(8) {
    min-width: 3em !important;
  }
  .leaderboard-container th:nth-child(8) {
    min-width: 3em !important;
    text-align: right !important;
  }

  .leaderboard-container th:nth-child(10) {
    min-width: 4em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(10) {
    min-width: 5em !important;
  }

  .leaderboard-container th:nth-child(12) {
    min-width: 4em;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(12) {
    min-width: 4em;
  }

  .leaderboard-container th:nth-child(13) {
    min-width: 5em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(13) {
    min-width: 5em !important;
  }

  .leaderboard-container th:nth-child(14) {
    min-width: 3em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(14) {
    min-width: 3em !important;
  }
  .leaderboard-container th:nth-child(15) {
    min-width: 3em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(15) {
    min-width: 3em !important;
  }
  .leaderboard-container th:nth-child(16) {
    min-width: 3em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(16) {
    min-width: 3em !important;
  }
  .leaderboard-container th:nth-child(17) {
    min-width: 3em !important;
    text-align: right !important;
  }

  .leaderboard-container td:nth-child(17) {
    min-width: 3em !important;
  }

  .visible-on-screen {
    display: block;
  }

  .visible-on-mobile {
    display: none;
  }

  .other-container {
    width: 80%;
  }

  .profile-container {
    width: 90%;
    margin: 0 auto;
  }

  .profile-container td:nth-child(4) {
    text-align: right !important;
  }

  .profile-container th:nth-child(4) {
    text-align: right !important;
  }

  .profile-container td:last-child {
    width: 6em;
  }

  .profile-container th:last-child {
    width: 6em;
  }

  .submission-container {
    width: 80%;
    margin: 0 auto;
  }

  .submission-container th:last-child {
    width: 7em !important;
  }

  .submission-container td:last-child {
    width: 7em !important;
  }

  .App-container {
    width: 70%;
    font-size: 1em;
  }

  .App-intro-content {
    width: 80%;
  }

  .other-container {
    font-size: 0.9em;
  }
}

@media only screen and (min-width: 1460px)  {
  .App-intro-content {
    width: 80%;
  }

  .App-container {
    width: 50%;
    font-size: 1em;
  }

  .institute-logo-link:first-child {
    padding-left: 10%;
  }

  .institute-logo-link:last-child {
    padding-right: 10%;
  }

  .other-container {
    font-size: 1em;
  }

  .tasks-container {
    width: 70%;
    margin: 0 auto;
  }

  .leaderboard-container {
    width: 80%;
    margin: 0 auto;
    font-size: 0.9em !important;
  }

  .submission-container {
    width: 60%;
    margin: 0 auto;
  }

  .profile-container {
    width: 55%;
    margin: 0 auto;
  }
}

/**
 * Background color: #343233
 * Primary Color: #efdab9
 * Secondary Color: #8bbbae
 * Tertiary Color: #e2ad4b
 */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #343233;
  color: #efdab9;
}

