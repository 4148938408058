/**
 * Background color: #343233
 * Primary Color: #efdab9
 * Secondary Color: #8bbbae
 * Tertiary Color: #e2ad4b
 */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #343233;
  color: #efdab9;
}
